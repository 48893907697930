<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="订单编号">
              <a-input
                v-model:value.trim="where.orderNumber"
                placeholder="请输入订单编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="订单状态:">
              <a-select
                allow-clear
                placeholder="请选择订单状态"
                v-model:value="where.orderStatus"
              >
                <a-select-option
                  v-for="item in orderStatus"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="发货状态:">
              <a-select
                allow-clear
                placeholder="请选择发货状态"
                v-model:value="where.deliveryStatus"
              >
                <a-select-option
                  v-for="item in delivery"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="收货状态:">
              <a-select
                allow-clear
                placeholder="请选择收货状态"
                v-model:value="where.receiptStatus"
              >
                <a-select-option
                  v-for="item in receipt"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="订单时间:">
              <a-range-picker
                v-model:value="daterange"
                value-format="YYYY-MM-DD"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="orderId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #img="{ record }">
          <img :src="record.goodsMainPic" width="50" height="50" />
        </template>
        <template #delivery="{ record }">
          <a-tag
            :color="
              record.deliveryStatusName === '未发货'
                ? 'volcano'
                : record.deliveryStatusName === '已发货'
                ? 'green'
                : record.deliveryStatusName === '已完成'
                ? 'geekblue'
                : ''
            "
          >
            {{ record.deliveryStatusName }}
          </a-tag>
        </template>
        <template #receipt="{ record }">
          <a-tag
            :color="
              record.receiptStatusName === '未收货'
                ? 'red'
                : record.receiptStatusName === '已收货'
                ? 'geekblue'
                : ''
            "
          >
            {{ record.receiptStatusName }}
          </a-tag>
        </template>
        <template #action="{ record }">
          <a-space>
            <a
              v-if="record.deliveryStatusName === '未发货'"
              @click="deliverClick(record)"
              >订单发货</a
            >
            <a v-else @click="deliverClick(record)">修改物流</a>
            <a @click="addressClick(record)">修改地址</a>
            <a @click="openDetail(record)">详情</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 发货-修改物流 -->
    <!-- <LogisticsEdit v-model:visible="logEdit" v-model:data="current" @done="reload" @openClick="openClick" /> -->
    <!-- 修改收货地址 -->
    <!-- <AddressEdit v-model:visible="addressEdit" v-model:data="current" @done="reload" /> -->
    <!-- 详情 -->
    <!-- <OrderDetail v-model:visible="showDetail" v-model:data="current" @done="reload" /> -->
  </div>
</template>

<script>
// import * as pointsOrderApi from '@/api/jyyw/pointsOrder'
// import * as dictDataApi from '@/api/sys/dictData'
// import LogisticsEdit from './logistics-edit.vue'
// import AddressEdit from './receipt-edit.vue'
// import OrderDetail from './order-detail.vue'
import { DeleteOutlined } from '@ant-design/icons-vue'
export default {
  name: 'order-delivery',
  components: {
    DeleteOutlined
    // LogisticsEdit,
    // AddressEdit,
    // OrderDetail,
    // ExportEdit,
    // ImportEdit
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: '订单编号',
          dataIndex: 'orderNumber',
          fixed: 'left'
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatusName',
          sorter: true,
          fixed: 'left'
        },
        {
          title: '订单时间',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName'
        },
        {
          title: '商品图片',
          dataIndex: 'goodsMainPic',
          sorter: true,
          slots: { customRender: 'img' }
        },
        {
          title: '商品价格',
          dataIndex: 'unitPoint',
          sorter: true
        },
        {
          title: '实付金额',
          dataIndex: 'payPoints',
          sorter: true
        },
        {
          title: '商品数量',
          dataIndex: 'goodsCount',
          sorter: true
        },
        {
          title: '运费金额',
          dataIndex: 'expressPriceName',
          sorter: true
        },
        {
          title: '是否发货',
          dataIndex: 'deliveryStatusName',
          sorter: true,
          key: 'delivery',
          slots: { customRender: 'delivery' }
        },
        {
          title: '快递公司',
          dataIndex: 'expressCompany'
        },
        {
          title: '快递单号',
          dataIndex: 'expressNo'
        },
        {
          title: '收货人',
          dataIndex: 'receiptName'
        },
        {
          title: '收货人电话',
          dataIndex: 'receiptPhone'
        },
        {
          title: '收货人地区',
          dataIndex: 'receiptAreacodeName'
        },
        {
          title: '收货人详细地址',
          dataIndex: 'receiptDetail'
        },
        {
          title: '收货状态',
          dataIndex: 'receiptStatusName',
          sorter: true,
          key: 'receipt',
          slots: { customRender: 'receipt' }
        },
        {
          title: '付款时间',
          dataIndex: 'payTime'
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '220px',
          align: 'center',
          slots: { customRender: 'action' },
          fixed: 'right'
        }
      ],
      datasource: [
        {
          key: '1',
          orderNumber: 'bt11102978675889',
          orderStatusName: '已付款',
          createTime: '2022-08-26 08:12:43',
          goodsName: '锅巴',
          scribingPrice: '59',
          amount: 99,
          goodsWeight: '0.5',
          sku: '12345',
          套餐: '这是套餐'
        },
        {
          key: '2',
          orderNumber: 'bt11102978671222',
          orderStatusName: '已付款',
          createTime: '2022-08-26 08:20:06',
          goodsName: '白酒',
          scribingPrice: '12',
          amount: 99,
          goodsWeight: '0.5',
          sku: '224466'
        },
        {
          key: '3',
          orderNumber: 'bt11102978675624',
          orderStatusName: '已付款',
          createTime: '2022-08-26 09:24:36',
          goodsName: 'mac电脑',
          scribingPrice: '12',
          amount: 99,
          goodsWeight: '0.5',
          sku: '224466'
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: { sort: 'createTime', order: 'desc' },
      // 表格选中数据
      selection: [],
      // 是否显示发货弹窗
      logEdit: false,
      // 是否显示修改地址弹窗
      addressEdit: false,
      // 是否显示详情弹窗
      showDetail: false,

      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false,
      categoryList: [],
      // 订单状态字典项
      orderStatus: [],
      // 发货状态字典项
      delivery: [],
      // 收货状态字典项
      receipt: [],
      // 日期范围选择
      daterange: []
    }
  },
  created() {
    // this.queryDataRoles()
    // this.queryDeliveryRoles()
    // this.queryReceiptRoles()
  },
  watch: {
    // daterange() {
    //   if (this.daterange && this.daterange.length === 2) {
    //     this.where.createTimeStart = this.daterange[0] + ' ' + '00:00:00'
    //     this.where.createTimeEnd = this.daterange[1] + ' ' + '23:59:59'
    //   } else {
    //     this.where.createTimeStart = null
    //     this.where.createTimeEnd = null
    //   }
    // }
  },
  methods: {
    // datasource(option, callback) {
    //   console.log('option', option)
    //   pointsOrderApi
    //     .page({
    //       ...this.where,
    //       ...this.order,
    //       page: option.page,
    //       limit: option.limit,
    //       sort: 'createTime',
    //       order: 'desc'
    //     })
    //     .then((res) => {
    //       if (res.code === 0) {
    //         res.data.map((item) => {
    //           // if (item.orderStatusName === 10) {
    //           //   item.orderStatusName = '未发货'
    //           // } else if (item.goodsStatus === 20) {
    //           //   item.orderStatusName = '已发货'
    //           // } else if (item.goodsStatus === 30) {
    //           //   item.orderStatusName = '完成'
    //           // }
    //           if (item.expressPrice === null) {
    //             item.expressPriceName = '0.00'
    //           }
    //         })

    //         callback(res.data, res.count)
    //       } else {
    //         callback(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       callback(e.message)
    //     })
    // },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.daterange = []
      this.$nextTick(() => {
        this.reload()
      })
    },
    importClick() {
      console.log('点击了导出')
    },
    // /* 删除单个 */
    // remove(row) {
    //   const hide = this.$message.loading('请求中..', 0)
    //   pointsOrderApi
    //     .deleteById(row.orderId)
    //     .then((res) => {
    //       hide()
    //       if (res.code === 0) {
    //         this.$message.success(res.msg)
    //         this.reload()
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       hide()
    //       this.$message.error(e.message)
    //     })
    // },
    // /* 批量删除 */
    // removeBatch() {
    //   if (!this.selection.length) {
    //     this.$message.error('请至少选择一条数据')
    //     return
    //   }
    //   this.$confirm({
    //     title: '提示',
    //     content: '确定要删除选中的项吗?',
    //     maskClosable: true,
    //     onOk: () => {
    //       const hide = this.$message.loading('请求中..', 0)
    //       const data = { data: this.selection.map((d) => d.orderId) }
    //       pointsOrderApi
    //         .deleteBatch(data)
    //         .then((res) => {
    //           hide()
    //           if (res.code === 0) {
    //             this.$message.success(res.msg)
    //             this.reload()
    //           } else {
    //             this.$message.error(res.msg)
    //           }
    //         })
    //         .catch((e) => {
    //           hide()
    //           this.$message.error(e.message)
    //         })
    //     }
    //   })
    // },
    // 物流或修改物流信息按钮
    deliverClick(row) {
      console.log('物流', row)
      this.current = row
      this.logEdit = true
    },
    // 修改地址按钮
    addressClick(row) {
      this.current = row
      this.addressEdit = true
    },
    /* 详情 */
    openDetail(row) {
      this.current = row
      this.showDetail = true
    }
    // openClick() {
    //   console.log('父组件的方法')
    //   this.addressEdit = true
    // },
    // 订单状态
    // queryDataRoles() {
    //   dictDataApi
    //     .querydictdata('orderState')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.orderStatus = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    // // 发货状态
    // queryDeliveryRoles() {
    //   dictDataApi
    //     .querydictdata('deliveryStatus')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.delivery = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    // // 发货状态
    // queryReceiptRoles() {
    //   dictDataApi
    //     .querydictdata('receiptStatus')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.receipt = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // }
  }
}
</script>

<style scoped>
/* .max-content {
  width: 900px;
} */
</style>
